// ** React Imports
import { useContext, useMemo, useCallback, memo } from "react";
import { Link } from "react-router-dom";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Custom Components
import Block from "components/layout/Block";
import Section from "components/layout/Section";
import SimpleMenu from "components/layout/SimpleMenu";
import PresentationTiles from "./PresentationTiles";
import PicturesList from "./PicturesList";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";

// ** Utils & Helpers
import pseudoFormat from "utils/pseudoFormat";

// ** Routes
import containers, { routesArray } from "containers";

// ** Styled Components
import { StyledCol as Col } from "styles/components/Col.styled";

// #####################################################

const FeaturesAndMenuSection = () => {
	const { t } = useTranslation(["common", "landing"]);

	const { isDesktop } = useContext(deviceTypeContext);

	const language = useSelector((state) => state.global.language);
	const camsOnline = useSelector((state) => state.global.statistics.cams);
	const registeredUsers = useSelector(
		(state) => state.global.statisticsMain.users
	);
	const landingImages = useSelector(
		(state) => state.mainPages.landing.data.images
	);

	const mainPageNavItems = useMemo(() => {
		return routesArray
			.filter(({ meta }) => meta.mainNavItem)
			.filter(({ key }) => key !== containers.activity.key)
			.filter(({ key }) => key !== containers.nearby.key)
			.sort((a, b) => {
				if (a.meta.mainNavIndex > b.meta.mainNavIndex) return 1;
				if (a.meta.mainNavIndex < b.meta.mainNavIndex) return -1;
				return 0;
			})
			.map((route) => {
				let content = "";
				content += t(`pages:${route.meta.transKey || route.key}`);
				if (route.key === "cameras") {
					content += ` (${pseudoFormat(camsOnline)})`;
				}
				return {
					key: route.key,
					content,
					link: {
						to: route.link,
						query: route.meta?.initialQuery,
					},
					icon: route.meta.icon || null,
				};
			});
	}, [language, camsOnline]);

	const renderMainPageNav = useCallback(() => {
		return (
			<SimpleMenu.List>
				{mainPageNavItems.map((item, i) => {
					return (
						<SimpleMenu.Item
							onClick={() => {
								setTimeout(() => {
									window.scrollTo(0, 0);
								}, 0);
							}}
							to={item.link.to}
							as={Link}
							key={item.key || `item-${i}`}
						>
							{item.content}
						</SimpleMenu.Item>
					);
				})}
			</SimpleMenu.List>
		);
	}, [mainPageNavItems]);

	// #####################################################

	return (
		<Section>
			{isDesktop ? (
				<Block flex flexWrap>
					<Col md={6}>
						<PresentationTiles registeredUsers={registeredUsers} />
					</Col>
					<Col md={6}>
						<PicturesList images={landingImages || []} />
					</Col>
				</Block>
			) : (
				<>
					<Block flex flexWrap>
						<PicturesList images={landingImages || []} />
					</Block>
					<Block flex flexWrap>
						<PresentationTiles registeredUsers={registeredUsers} />
					</Block>
				</>
			)}
			<Block flex flexWrap>
				<Block flex full justifyCenter mt={16}>
					<SimpleMenu title={t("landing:menu")} size="1.3rem">
						{renderMainPageNav()}
					</SimpleMenu>
				</Block>
			</Block>
		</Section>
	);
};

// #####################################################

export default memo(FeaturesAndMenuSection);

// #####################################################
